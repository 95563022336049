import { default as change2tVUypx2e9Meta } from "/tmp/build_68cfb628/pages/account/change.vue?macro=true";
import { default as indexVeYFwtDTqlMeta } from "/tmp/build_68cfb628/pages/account/index.vue?macro=true";
import { default as loginMLPxAu5iAMMeta } from "/tmp/build_68cfb628/pages/account/login.vue?macro=true";
import { default as _91token_93v4n5ut437nMeta } from "/tmp/build_68cfb628/pages/account/password-reset/[uidb64]/[token].vue?macro=true";
import { default as completeIN5cHWiL5vMeta } from "/tmp/build_68cfb628/pages/account/password-reset/complete.vue?macro=true";
import { default as doneeKPQ9rIUFWMeta } from "/tmp/build_68cfb628/pages/account/password-reset/done.vue?macro=true";
import { default as indexp9Sdu9FUDMMeta } from "/tmp/build_68cfb628/pages/account/password-reset/index.vue?macro=true";
import { default as password_45resetGRkZ6VlPm2Meta } from "/tmp/build_68cfb628/pages/account/password-reset.vue?macro=true";
import { default as passwordxVDwZ0ixSVMeta } from "/tmp/build_68cfb628/pages/account/password.vue?macro=true";
import { default as registerwvq5ExNyhlMeta } from "/tmp/build_68cfb628/pages/account/register.vue?macro=true";
import { default as cookie_45policy0fPk1L8oTBMeta } from "/tmp/build_68cfb628/pages/docs/cookie-policy.vue?macro=true";
import { default as privacy_45policykvlXjvwLtBMeta } from "/tmp/build_68cfb628/pages/docs/privacy-policy.vue?macro=true";
import { default as terms_45of_45useCR3SNZXTZYMeta } from "/tmp/build_68cfb628/pages/docs/terms-of-use.vue?macro=true";
import { default as _91slug_93rf5J7YyA6BMeta } from "/tmp/build_68cfb628/pages/events/[slug].vue?macro=true";
import { default as indexbjyBqegx8yMeta } from "/tmp/build_68cfb628/pages/events/index.vue?macro=true";
import { default as indexvIuWbTSieAMeta } from "/tmp/build_68cfb628/pages/index.vue?macro=true";
import { default as _91id_93KRDxEhIUKVMeta } from "/tmp/build_68cfb628/pages/orders/[id].vue?macro=true";
import { default as indexh8ojWqoVOpMeta } from "/tmp/build_68cfb628/pages/orders/index.vue?macro=true";
import { default as index24FbHF0YgiMeta } from "/tmp/build_68cfb628/pages/purchase/index.vue?macro=true";
export default [
  {
    name: "account-change",
    path: "/account/change",
    meta: change2tVUypx2e9Meta || {},
    component: () => import("/tmp/build_68cfb628/pages/account/change.vue").then(m => m.default || m)
  },
  {
    name: "account",
    path: "/account",
    meta: indexVeYFwtDTqlMeta || {},
    component: () => import("/tmp/build_68cfb628/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-login",
    path: "/account/login",
    meta: loginMLPxAu5iAMMeta || {},
    component: () => import("/tmp/build_68cfb628/pages/account/login.vue").then(m => m.default || m)
  },
  {
    name: password_45resetGRkZ6VlPm2Meta?.name,
    path: "/account/password-reset",
    meta: password_45resetGRkZ6VlPm2Meta || {},
    component: () => import("/tmp/build_68cfb628/pages/account/password-reset.vue").then(m => m.default || m),
    children: [
  {
    name: "account-password-reset-uidb64-token",
    path: ":uidb64()/:token()",
    component: () => import("/tmp/build_68cfb628/pages/account/password-reset/[uidb64]/[token].vue").then(m => m.default || m)
  },
  {
    name: "account-password-reset-complete",
    path: "complete",
    component: () => import("/tmp/build_68cfb628/pages/account/password-reset/complete.vue").then(m => m.default || m)
  },
  {
    name: "account-password-reset-done",
    path: "done",
    component: () => import("/tmp/build_68cfb628/pages/account/password-reset/done.vue").then(m => m.default || m)
  },
  {
    name: "account-password-reset",
    path: "",
    component: () => import("/tmp/build_68cfb628/pages/account/password-reset/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "account-password",
    path: "/account/password",
    meta: passwordxVDwZ0ixSVMeta || {},
    component: () => import("/tmp/build_68cfb628/pages/account/password.vue").then(m => m.default || m)
  },
  {
    name: "account-register",
    path: "/account/register",
    meta: registerwvq5ExNyhlMeta || {},
    component: () => import("/tmp/build_68cfb628/pages/account/register.vue").then(m => m.default || m)
  },
  {
    name: "docs-cookie-policy",
    path: "/docs/cookie-policy",
    component: () => import("/tmp/build_68cfb628/pages/docs/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: "docs-privacy-policy",
    path: "/docs/privacy-policy",
    component: () => import("/tmp/build_68cfb628/pages/docs/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "docs-terms-of-use",
    path: "/docs/terms-of-use",
    component: () => import("/tmp/build_68cfb628/pages/docs/terms-of-use.vue").then(m => m.default || m)
  },
  {
    name: "events-slug",
    path: "/events/:slug()",
    component: () => import("/tmp/build_68cfb628/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: "events",
    path: "/events",
    component: () => import("/tmp/build_68cfb628/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/tmp/build_68cfb628/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "orders-id",
    path: "/orders/:id()",
    component: () => import("/tmp/build_68cfb628/pages/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: "orders",
    path: "/orders",
    meta: indexh8ojWqoVOpMeta || {},
    component: () => import("/tmp/build_68cfb628/pages/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "purchase",
    path: "/purchase",
    component: () => import("/tmp/build_68cfb628/pages/purchase/index.vue").then(m => m.default || m)
  }
]